import React from "react"
import { BrowserRouter as Router, Route, Switch} from "react-router-dom"

import Homepage from './Homepage'
import Mine from './Mine'

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Homepage} />
      <Route path="/be-mine" component={Mine} />
    </Switch>
  </Router>
)

export default App