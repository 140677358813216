import React, { Component } from 'react'
import Typist from 'react-typist'
import TextLoop from 'react-text-loop'

import './Mine.scss'


class Mine extends Component {
  constructor(props) {
    super(props)
    this.state = {
      first: false,
      second: false,
      third: false,
      fourth: false,
    }
  }

  firstTyped = () => {
    this.setState({first: true})
  }

  secondTyped = () => {
    this.setState({second: true})
  }

  thirdTyped = () => {
    this.setState({third: true})
  }

  fourthTyped = () => {
    this.setState({fourth: true})
  }

  render() {
    return(
      <div className="outer-container">
        <svg className="heart heart1" viewBox="0 0 32 29.6">
          <path d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
          c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"/>
        </svg>
        <h1>Emily Wiles</h1>
        <svg className="heart heart2" viewBox="0 0 32 29.6">
          <path d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
          c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"/>
        </svg>

        <div className="main">
          <p>
            <Typist
              startDelay={2500}
              cursor={{ show: false }}
              onTypingDone={this.firstTyped}
            >
            Not only are you the best<Typist.Backspace count={4} delay={500} />
            most amazing<Typist.Backspace count={16} delay={1500} />
            an incredible mother to our wonderful children.
            You are the love of my life.
            <Typist.Delay ms={800} />
            </Typist>
          </p>
          {this.state.first ? (
          <p>
            <Typist
              startDelay={2000}
              cursor={{ show: false }}
              onTypingDone={this.secondTyped}
            >
            You are my reason for getting up in the morning. <Typist.Delay ms={750} />
            The reason to be better. <Typist.Delay ms={1200} />
            My happiness. <Typist.Delay ms={800} />My best friend.
            <Typist.Delay ms={600} /> My babe!
            </Typist>
          </p>
          ) : null}
          {this.state.second ? (
          <p>
            <Typist
              startDelay={3000}
              cursor={{ show: false }}
              onTypingDone={this.thirdTyped}
            >
              I don't say it right.<Typist.Backspace count={6} delay={1500} />well<Typist.Backspace count={4} delay={500} />often enough.
              <Typist.Delay ms={750} /> Can't say it often enough, but please know
              I think it and mean it every second of every day. And in case you ever
              forget it...<Typist.Backspace count={12} delay={700} />question it...
              <Typist.Backspace count={14} delay={400} />need a reminder, this
              permanent Valentine's Day card will be here waiting for you.
            </Typist>
          </p>
          ) : null}
        </div>

        <p className="signature">
        {this.state.third ? (
          <Typist
            startDelay={800}
            cursor={{ show: false }}
            onTypingDone={this.fourthTyped}
          >
            Love you more,<Typist.Delay ms={900} /><br />
            <span>Frank</span>
          </Typist>
        ) : null}
        </p>

        <footer>
        {this.state.fourth ? (
          <React.Fragment>
          Emily...&nbsp;
          <TextLoop delay={2000} fade={true}>
            <span>is beautiful</span>
            <span>is special</span>
            <span>is wonderful</span>
            <span>is the bestest!</span>
            <span>is my moon and stars</span>
            <span>deserves to be told these things daily</span>
            <span>is amazing</span>
            <span>est mon petit chou</span>
            <span>is something else!</span>
            <span>is married to a great guy! hehehe </span>
            <span>is fun</span>
            <span>is funny</span>
            <span>maybe drinks too much coffee</span>
            <span>is my babe</span>
            <span>is an awesome mom</span>
            <span>is the best snuggler</span>
            <span>is talented</span>
            <span>is awesome</span>
            <span>is lovely</span>
          </TextLoop>
          </React.Fragment>
        ) : null}
        </footer>
      </div>
    )
  }
}

export default Mine
