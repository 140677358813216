import React, { Component } from 'react';
import './Homepage.scss';
import countdown from 'countdown';
import Gallery from 'react-photo-gallery';

import porch from './images/front-porch.jpg';
import burgerstand from './images/burgerstand.jpg';
import farmBottomsBW from './images/farm-bottoms-bw.jpg';
import farmBottoms from './images/farm-bottoms.jpg';
import grass1BW from './images/grass1-bw.jpg';
import grass1 from './images/grass1.jpg';
import grass2 from './images/grass2.jpg';
import grass3 from './images/grass3.jpg';
import jb1 from './images/jb1.jpg';
import jb2 from './images/jb2.jpg';
import kidsBed from './images/kids-bed.jpg';
import kidsFloor from './images/kids-floor.jpg';
import kitchenKiss from './images/kitchen-kiss.jpg';
import sandbar from './images/sandbar.jpg';

const PHOTO_SET = [
  {
    src: porch,
    height: 3,
    width: 2,
  },
  {
    src: kidsFloor,
    width: 3,
    height: 2,
  },
  {
    src: farmBottoms,
    width: 3,
    height: 2,
  },
  {
    src: burgerstand,
    width: 3,
    height: 2,
  },
  {
    src: grass1,
    width: 3,
    height: 2,
  },
  {
    src: kidsBed,
    width: 3,
    height: 2,
  },
  {
    src: grass3,
    width: 3,
    height: 2,
  },
  {
    src: jb1,
    width: 3,
    height: 2,
  },
  {
    src: jb2,
    width: 3,
    height: 2,
  },
  {
    src: grass2,
    width: 3,
    height: 2,
  },
  {
    src: kitchenKiss,
    width: 3,
    height: 2,
  },
  {
    src: sandbar,
    width: 3,
    height: 2,
  },
];

class Homepage extends Component {
  constructor(props) {
    super(props);
    const s = this.createCountdown();
    this.state = {
      interval: undefined,
      countdown: s.toString(),
    };
  }

  componentDidMount() {
    const intervalId = setInterval(this.updateCountdown, 10000)
    this.setState({interval: intervalId})
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  createCountdown() {
    const cd = countdown(
      new Date(2014, 7, 23, 15, 30, 0),
      new Date(),
      countdown.YEARS | countdown.MONTHS | countdown.DAYS | countdown.HOURS | countdown.MINUTES,
    );
    const s = cd.toString()
    return s
  }

  updateCountdown = () => {
    const s = this.createCountdown();
    this.setState({countdown: s});
  }

  render() {
    return (
      <div className="container">
        <header>
          <h3>
            Frank &amp; Emily were married
            {' '}
            {this.state.countdown}
            {' '}
            ago
          </h3>
        </header>
        <Gallery photos={PHOTO_SET} />
      </div>
    );
  }
}

export default Homepage;
